/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import { RiPrinterLine, RiDownloadLine, RiArrowLeftLine, RiCheckLine, RiUserLine, RiSearchLine, RiLightbulbLine } from "react-icons/ri"

const SelfAssessmentTemplateDownloadPage = () => {
  
  // Function to handle printing the template
  const handlePrint = () => {
    if (typeof window !== 'undefined') {
      window.print();
    }
  }
  
  return (
    <Layout>
      <SEO 
        title="Self-Assessment Template | Employee Reflection Tool"
        description="Download our self-assessment template to facilitate employee reflection on achievements, skills, and development areas. Empower employees in the performance review process."
        keywords={[
          "self-assessment template",
          "employee self-evaluation",
          "performance self-review",
          "self-reflection worksheet",
          "professional development assessment",
          "skills evaluation template",
          "achievements documentation form",
          "personal growth assessment",
          "strengths identification tool",
          "career development template"
        ]}
      />
      
      {/* Hero Section */}
      <div sx={{
        background: 'linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%)',
        py: [4, 5],
        position: 'relative'
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <div sx={{
            maxWidth: '800px',
            mx: 'auto'
          }}>
            <Link 
              to="/guides/performance-reviews" 
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                color: 'primary',
                fontSize: '0.9rem',
                textDecoration: 'none',
                mb: 3,
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >
              <RiArrowLeftLine sx={{ mr: 1 }} /> Back to performance reviews guide
            </Link>
            
            <h1 sx={{
              fontSize: ['1.8rem', '2.2rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary'
            }}>
              Self-Assessment Template
            </h1>
            
            <p sx={{
              fontSize: '1rem',
              lineHeight: 1.5,
              mb: 4,
              color: 'text',
              opacity: 0.9
            }}>
              A structured guide for employees to reflect on their performance, accomplishments, 
              challenges, and development needs to facilitate more productive performance discussions.
            </p>
            
            <div sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 3,
              mb: 4
            }}>
              <button 
                onClick={handlePrint}
                type="button"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'primary',
                  color: 'white',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '0.95rem',
                  fontWeight: 600,
                  border: 'none',
                  cursor: 'pointer',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.15)'
                  }
                }}
              >
                <RiPrinterLine /> Print Template
              </button>
              
              <Link 
                to="/contact"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'white',
                  color: 'primary',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '0.95rem',
                  fontWeight: 600,
                  textDecoration: 'none',
                  border: '2px solid',
                  borderColor: 'primary',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  boxShadow: '0 4px 10px rgba(0,0,0,0.05)',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                  }
                }}
              >
                <RiDownloadLine /> Request Full Template
              </Link>
            </div>
          </div>
        </div>
      </div>
      
      {/* Main content */}
      <div className="printable-content" sx={{
        maxWidth: '1200px',
        mx: 'auto',
        px: 3,
        py: [4, 5],
        '@media print': {
          padding: '0',
          fontSize: '12pt'
        }
      }}>
        <div sx={{
          maxWidth: '800px',
          mx: 'auto',
          bg: 'white',
          p: [3, 4],
          borderRadius: '8px',
          boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
          '@media print': {
            boxShadow: 'none',
            padding: '0'
          }
        }}>
          {/* Introduction Section */}
          <section sx={{ mb: 5 }}>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              Introduction to Self-Assessment
            </h2>
            <p sx={{ mb: 3, lineHeight: 1.6 }}>
              Self-assessment is a powerful tool that encourages employees to reflect on their performance, 
              achievements, challenges, and development areas. This reflective process empowers employees 
              to take an active role in the performance review conversation.
            </p>
            <p sx={{ lineHeight: 1.6 }}>
              By completing a thoughtful self-assessment, employees can better articulate their contributions, 
              identify areas where they excel, recognize opportunities for growth, and express career aspirations. 
              This creates a more balanced and productive performance discussion.
            </p>
          </section>

          {/* Template Components */}
          <section sx={{ mb: 5 }}>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              Key Template Components
            </h2>
            
            <div sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
              gap: 4,
              mb: 4
            }}>
              {[
                {
                  title: "Accomplishments Review",
                  description: "Summary of key achievements, projects completed, and goals met during the review period",
                  icon: <RiCheckLine />
                },
                {
                  title: "Skills Assessment",
                  description: "Self-evaluation of core competencies, technical skills, and soft skills",
                  icon: <RiUserLine />
                },
                {
                  title: "Challenges & Solutions",
                  description: "Reflection on obstacles faced and how they were addressed or overcome",
                  icon: <RiSearchLine />
                },
                {
                  title: "Development Planning",
                  description: "Identification of growth areas and proposed learning opportunities",
                  icon: <RiLightbulbLine />
                }
              ].map((component) => (
                <div 
                  key={component.title}
                  sx={{
                    p: 3,
                    borderRadius: '8px',
                    border: '1px solid',
                    borderColor: 'muted',
                    bg: '#f8f9fa'
                  }}
                >
                  <div sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    mb: 2,
                    color: 'primary' 
                  }}>
                    <span sx={{ fontSize: '1.5rem', mr: 2 }}>{component.icon}</span>
                    <h3 sx={{ fontSize: '1.2rem', fontWeight: 600, m: 0 }}>
                      {component.title}
                    </h3>
                  </div>
                  <p sx={{ fontSize: '0.95rem', lineHeight: 1.6, m: 0 }}>
                    {component.description}
                  </p>
                </div>
              ))}
            </div>
          </section>
          
          {/* Template Preview */}
          <section sx={{ mb: 5 }}>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              Template Preview
            </h2>
            
            <div sx={{
              border: '1px solid',
              borderColor: 'muted',
              borderRadius: '6px',
              p: 3,
              mb: 4
            }}>
              <h3 sx={{ 
                fontSize: '1.2rem', 
                fontWeight: 700,
                mb: 3,
                color: 'text'
              }}>
                Accomplishments Section Sample
              </h3>
              
              <div sx={{
                p: 3,
                bg: '#f8f9fa',
                borderRadius: '6px',
                mb: 4
              }}>
                <div sx={{ mb: 3 }}>
                  <div sx={{ 
                    fontWeight: 600, 
                    fontSize: '1.1rem', 
                    mb: 2,
                    color: 'primary'
                  }}>
                    Key Achievements
                  </div>
                  <div sx={{ 
                    border: '1px solid',
                    borderColor: 'muted',
                    borderRadius: '4px',
                    p: 3,
                    bg: 'white',
                    fontSize: '0.9rem',
                    minHeight: '80px',
                    fontStyle: 'italic',
                    color: 'text',
                    opacity: 0.8
                  }}>
                    List 3-5 significant accomplishments during this review period. Include metrics where possible 
                    and describe your specific contribution to each achievement.
                  </div>
                </div>
                
                <div sx={{ mb: 3 }}>
                  <div sx={{ 
                    fontWeight: 600, 
                    fontSize: '1.1rem', 
                    mb: 2,
                    color: 'primary'
                  }}>
                    Goals Review
                  </div>
                  <div sx={{ 
                    border: '1px solid',
                    borderColor: 'muted',
                    borderRadius: '4px',
                    p: 3,
                    bg: 'white',
                    fontSize: '0.9rem',
                    minHeight: '80px',
                    fontStyle: 'italic',
                    color: 'text',
                    opacity: 0.8
                  }}>
                    Evaluate your progress on each of the goals set during your last review. 
                    For incomplete goals, explain factors that affected progress.
                  </div>
                </div>
                
                <div>
                  <div sx={{ 
                    fontWeight: 600, 
                    fontSize: '1.1rem', 
                    mb: 2,
                    color: 'primary'
                  }}>
                    Additional Contributions
                  </div>
                  <div sx={{ 
                    border: '1px solid',
                    borderColor: 'muted',
                    borderRadius: '4px',
                    p: 3,
                    bg: 'white',
                    fontSize: '0.9rem',
                    minHeight: '80px',
                    fontStyle: 'italic',
                    color: 'text',
                    opacity: 0.8
                  }}>
                    Describe any contributions beyond your core responsibilities, such as committee work, 
                    mentoring colleagues, process improvements, or other initiatives.
                  </div>
                </div>
              </div>
              
              {/* Additional template content preview */}
              <div sx={{ 
                bg: '#f8f9fa',
                p: 3,
                borderRadius: '6px',
                textAlign: 'center',
                border: '1px dashed',
                borderColor: 'muted',
                fontSize: '0.9rem',
                color: 'text',
                opacity: 0.8
              }}>
                The complete template includes additional sections for skills assessment, challenges encountered,
                professional development planning, and career aspirations. Request the full template to access all content.
              </div>
            </div>
          </section>
          
          {/* Benefits Section */}
          <section>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              Benefits of Using This Template
            </h2>
            
            <ul sx={{ pl: 0, listStyleType: 'none' }}>
              {[
                "Empowers employees to actively participate in the performance review process",
                "Creates a comprehensive record of achievements that might otherwise be overlooked",
                "Encourages regular reflection on performance, skills, and development needs",
                "Provides managers with valuable insights into employee perspectives and priorities",
                "Prepares employees to engage in more productive performance discussions",
                "Promotes self-awareness and ownership of professional development",
                "Facilitates alignment between employee and organizational goals"
              ].map((benefit) => (
                <li 
                  key={benefit}
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    mb: 3
                  }}
                >
                  <span sx={{ color: 'primary', mr: 2, pt: '3px' }}>
                    <RiCheckLine />
                  </span>
                  <span sx={{ fontSize: '0.95rem', lineHeight: 1.6 }}>
                    {benefit}
                  </span>
                </li>
              ))}
            </ul>
            
            <div sx={{
              bg: '#f8f9fa',
              p: 3,
              mt: 4,
              borderRadius: '6px',
              border: '1px solid',
              borderColor: 'muted',
              fontSize: '0.95rem',
              fontStyle: 'italic'
            }}>
              <strong>Best Practice:</strong> Encourage employees to maintain an ongoing "accomplishments journal" 
              throughout the year to track successes, challenges, and learning opportunities. This makes 
              completing the self-assessment much easier and more accurate when review time comes.
            </div>
          </section>
          
          {/* Print styles */}
          <style jsx>{`
            @media print {
              body {
                font-size: 12pt;
                color: #000;
              }
              h1, h2, h3, h4, h5, h6 {
                break-after: avoid;
              }
              ul, ol, table {
                break-inside: avoid;
              }
              a {
                text-decoration: none;
                color: #000;
              }
              .printable-content {
                padding: 0;
              }
            }
          `}</style>
        </div>
      </div>
    </Layout>
  )
}

export default SelfAssessmentTemplateDownloadPage 